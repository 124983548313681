import React, { useEffect, useState } from "react";
import Logo from "../../assets/img/logo.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import slugify from "react-slugify";
import Airport from "../../assets/img/airport-service.svg";
import Chauffeur from "../../assets/img/nav-chauffeur.svg";
import Wedding from "../../assets/img/nav-weeding.svg";
import Leisure from "../../assets/img/nav-leisure.svg";
import Video from "../../assets/img/nav-video.svg";
import Keys from "../../assets/img/nav-keys.svg";
import Corporate from "../../assets/img/corporate.png";
import Group from "../../assets/img/group.png";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const [data, setData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleDropdown = () => {
    navigate("/services");
  };

  useEffect(() => {
    fetch(`https://app.limousine4hire.net/l4h-backend/api/admin/services/getActiveServices`)
      .then((res) => res.json())
      .then((resData) => {
        setData(resData);
      });
  }, []);

  return (
    <header id="main-header">
      <nav className="navbar navbar-expand-lg main-nav-bar navbar-dark">
        <div id="no-flex" className="container">
          <div className="row">
            <div className="col-lg-3 col-xs-4">
              <Link className="navbar-brand" to={"/"}>
                <img src={Logo} alt="logo" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#main-navbar"
                aria-controls="main-navbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>

            <div className="col-lg-6 col-xs-4">
              <Navbar id="main-navbar">
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                  <Nav
                    className="me-auto justify-content-between m-auto"
                    style={{ width: "85%" }}
                    navbar
                  >
                    <React.Fragment>
                      <NavLink
                        className="nav-link"
                        aria-current="page"
                        to={`/`}
                        style={{ fontSize: "14px" }}
                        activeclassname="active"
                      >
                        Book a Ride
                      </NavLink>

                      <UncontrolledDropdown
                        nav
                        inNavbar
                        onMouseOver={() => setDropdownOpen(true)}
                        onMouseLeave={() => setDropdownOpen(false)}
                        isOpen={dropdownOpen}
                        toggle={toggleDropdown}
                      >
                        <DropdownToggle
                          nav
                          caret
                          onClick={handleDropdown}
                          style={{ fontSize: "14px" }}
                          className={pathname == "/services" ? "active" : ""}
                        >
                          Services
                        </DropdownToggle>
                        <DropdownMenu end>
                          {data?.map((el, i) => {
                            const jsonData = JSON.parse(el?.urls);
                            const icon = jsonData?.filter((el) =>
                              el?.name.includes("icon")
                            );
                            return (
                              <React.Fragment key={i}>
                                <DropdownItem>
                                  <Link to={el.page_url}>
                                    <img
                                      src={`https://app.limousine4hire.net/l4h-backend/${icon[0]?.name}`}
                                      alt="icon"
                                      style={{
                                        width: "7%",
                                        marginRight: "5px",
                                      }}
                                    />
                                    {el.title}
                                  </Link>
                                </DropdownItem>
                              </React.Fragment>
                            );
                          })}
                          {/* <DropdownItem>
                            <Link to={`/airport-service`}>
                              <i
                                className="fa-solid fa-plane"
                                style={{ color: "#000", marginRight: "5px" }}
                              ></i>
                              Airport Car Service
                            </Link>
                          </DropdownItem>
                          <DropdownItem>
                            <Link to={`/chauffeur-service`}>
                              <img
                                src={Chauffeur}
                                alt="icon"
                                style={{
                                  width: "7%",
                                  marginRight: "5px",
                                }}
                              />
                              Chauffeur Service
                            </Link>
                          </DropdownItem>
                          <DropdownItem>
                            <Link to={`/city-tours`}>
                              <i
                                className="fa-solid fa-city"
                                style={{ color: "#000", marginRight: "5px" }}
                              ></i>
                              City Tours
                            </Link>
                          </DropdownItem>
                          <DropdownItem>
                            <Link to={`/weddings`}>
                              <img
                                src={Wedding}
                                alt="icon"
                                style={{
                                  width: "7%",
                                  marginRight: "5px",
                                }}
                              />
                              Weddings and Events
                            </Link>
                          </DropdownItem>
                          <DropdownItem>
                            <Link to={`/corporate-transportation`}>
                              <img
                                src={Corporate}
                                alt="icon"
                                style={{
                                  width: "7%",
                                  marginRight: "5px",
                                }}
                              />
                              Corporate Transportation
                            </Link>
                          </DropdownItem>

                          <DropdownItem>
                            <Link to={`/leisure-transportation`}>
                              <img
                                src={Leisure}
                                alt="icon"
                                style={{
                                  width: "7%",
                                  marginRight: "5px",
                                }}
                              />
                              Leisure Transportation
                            </Link>
                          </DropdownItem>
                          <DropdownItem>
                            <Link to={`/group-transportation`}>
                              <img
                                src={Group}
                                alt="icon"
                                style={{
                                  width: "7%",
                                  marginRight: "5px",
                                }}
                              />
                              Group Transportation
                            </Link>
                          </DropdownItem>
                          <DropdownItem>
                            <Link to={`/entertainment`}>
                              <img
                                src={Video}
                                alt="icon"
                                style={{
                                  width: "7%",
                                  marginRight: "5px",
                                }}
                              />
                              Entertainment and Production
                            </Link>
                          </DropdownItem>
                          <DropdownItem>
                            <Link to={`/valet-service`}>
                              <img
                                src={Keys}
                                alt="icon"
                                style={{
                                  width: "7%",
                                  marginRight: "5px",
                                }}
                              />
                              Valet Service
                            </Link>
                          </DropdownItem> */}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      {/* <NavItem> */}
                      <NavLink
                        className="nav-link"
                        aria-current="page"
                        to={`/rates`}
                        activeclassname="active"
                        style={{ fontSize: "14px" }}
                      >
                        Rates
                      </NavLink>
                      {/* </NavItem> */}
                      {/* <NavItem> */}
                      <NavLink
                        className="nav-link"
                        aria-current="page"
                        to={`/blog`}
                        activeclassname="active"
                        style={{ fontSize: "14px" }}
                      >
                        Blog
                      </NavLink>
                      {/* </NavItem> */}
                      <NavLink
                        className="nav-link"
                        aria-current="page"
                        to={`/contact`}
                        activeclassname="active"
                        style={{ fontSize: "14px" }}
                      >
                        Contact Us
                      </NavLink>
                    </React.Fragment>
                  </Nav>
                </Collapse>
              </Navbar>
            </div>
            <div className="col-lg-3 col-xs-4">
              <div className="row">
                <div className="col-lg-12">
                  <div className="telephone">
                    <i className="fa-solid fa-phone"></i>{" "}
                    <span>
                      <a href={`tel:800-499-8128`} style={{ fontSize: "14px" }}>
                        800-499-8128
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="login-menu">
                    <ul className="login-reg">
                      <li>
                        <Link to={"/"} style={{ fontSize: "14px" }}>
                          <i className="fa-solid fa-user"></i>Login
                        </Link>
                      </li>
                      <li>
                        <Link to={"/"} style={{ fontSize: "14px" }}>
                          Register
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
