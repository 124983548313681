import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import slugify from "react-slugify";
import "./App.css";
import { Home } from "./pages/Home";
import { Blog } from "./pages/Blog";
import { Contact } from "./pages/Contact";
import { AirportService } from "./pages/AirportService";
import { ChauffeurService } from "./pages/ChauffeurService";
import { CityTours } from "./pages/CityTours";
import { CorporateTransportation } from "./pages/CorporateTransportation";
import { Entertainment } from "./pages/Entertainment";
import { GroupTransportation } from "./pages/GroupTransportation";
import { LeisureTransportation } from "./pages/LeisureTransportation";
import { ProductDetail } from "./pages/ProductDetail";
import { Rates } from "./pages/Rates";
import { Services } from "./pages/Services";
import { ValetService } from "./pages/ValetService";
import { Weddings } from "./pages/Weddings";
import { useEffect, useState } from "react";
import { PageService } from "./pages/Pages";
import { BlogDetail } from "./pages/BlogDetail";
import { ToastProvider } from "react-toast-notifications";

function App() {
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [pageUrls, setPageUrls] = useState({});
  const [globalSeo, setGlobalSeo] = useState({});

  useEffect(() => {
    fetch("https://app.limousine4hire.net/l4h-backend/api/admin/services/get")
      .then((res) => res.json())
      .then((resData) => {
        setData(resData);
      });
  }, []);

  useEffect(() => {
    fetch("https://app.limousine4hire.net/l4h-backend/api/admin/pages/get")
      .then((res) => res.json())
      .then((resData) => {
        const urls = {};
        const filteredData = resData.filter(
          (item) => item.page_url && item.page_url.trim() !== ""
        );

        filteredData.forEach((item) => {
          const { id, page_url } = item;
          urls[`page${id}url`] = page_url;
        });
        setPageUrls(urls);
      });
  }, []);

  useEffect(() => {
    fetch("https://app.limousine4hire.net/l4h-backend/api/admin/redirects/get")
      .then((res) => res.json())
      .then((resData) => {
        const url = resData?.find(
          (el) => window.location.pathname === "/" + el.oldUrl
        );

        if (url && url.newUrl) {
          try {
            navigate(`/${url.newUrl}`);
          } catch (error) {
            console.error("Invalid URL:", error);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    fetch("https://app.limousine4hire.net/l4h-backend/api/admin/fields/getByName/global_seo")
      .then((res) => res.json())
      .then((resData) => {
        setGlobalSeo(JSON.parse(resData.value));
      });
  }, []);

  return (
    <div className="App">
      <ToastProvider>
        <Routes>
          <Route exact path={pageUrls.page1url} element={<Home seoData={globalSeo?.page?.find(el=>el.value == pageUrls.page1url) ? globalSeo : {google_analytics: globalSeo.google_analytics, site_verification_meta_tag: globalSeo.site_verification_meta_tag}} />} />
          <Route exact path={pageUrls.page4url} element={<Blog seoData={globalSeo?.page?.find(el=>el.value == pageUrls.page4url) ? globalSeo : {google_analytics: globalSeo.google_analytics, site_verification_meta_tag: globalSeo.site_verification_meta_tag}} />} />
          <Route exact path="/blog-detail/:id" element={<BlogDetail />} />
          <Route exact path={pageUrls.page5url} element={<Contact seoData={globalSeo?.page?.find(el=>el.value == pageUrls.page5url) ? globalSeo : {google_analytics: globalSeo.google_analytics, site_verification_meta_tag: globalSeo.site_verification_meta_tag}} />} />
          {data?.map((el, i) => {
            if (el.action == 1) {
              return (
                <Route
                  exact
                  key={i}
                  path={el.page_url}
                  element={
                    <PageService
                      title={el.title}
                      heading={el.heading}
                      shortDescription={el.heading_two}
                      content={el.content}
                      images={JSON.parse(el.urls)}
                      bannerImageAltText={el.banner_image_alt_text}
                      iconAltText={el.icon_image_alt_text}
                      pageImageAltText={el.page_image_alt_text}
                      metaRobot={el.meta_robot}
                      pageUrl={el.page_url}
                      metaDescription={el.meta_description}
                      globalStructureMarkup={el.global_structure_markup}
                      seoData={globalSeo?.page?.find(item=>item.value == el.page_url) ? globalSeo : ""}
                    />
                  }
                />
              );
            }
          })}
          {/* <Route exact path="/airport-service" element={<AirportService />} />
          <Route exact path="/chauffeur-service" element={<ChauffeurService />} />
          <Route exact path="/city-tours" element={<CityTours />} />
          <Route exact path="/weddings" element={<Weddings />} />
          <Route exact path="/corporate-transportation" element={<CorporateTransportation />} />
          <Route exact path="/group-transportation" element={<GroupTransportation />} />
          <Route exact path="/leisure-transportation" element={<LeisureTransportation />} />
          <Route exact path="/entertainment" element={<Entertainment />} />
          <Route exact path="/valet-service" element={<ValetService />} /> */}
          <Route exact path="/product-detail/:id" element={<ProductDetail />} />
          <Route exact path={pageUrls.page2url} element={<Services seoData={globalSeo?.page?.find(el=>el.value == pageUrls.page2url) ? globalSeo : {google_analytics: globalSeo.google_analytics, site_verification_meta_tag: globalSeo.site_verification_meta_tag}} />} />
          <Route exact path={pageUrls.page3url} element={<Rates seoData={globalSeo?.page?.find(el=>el.value == pageUrls.page3url) ? globalSeo : {google_analytics: globalSeo.google_analytics, site_verification_meta_tag: globalSeo.site_verification_meta_tag}} />} />
        </Routes>
      </ToastProvider>
    </div>
  );
}

export default App;
